:root {
    /* CSS HEX */
    --dark-green: #006472;
    --teal: #03A2A5;
    --yellow: #C4A11B;
    --dark-blue: #11365A;
    --gray: #A1ABB2;
}

#root {
    height: 100%;
}

.App {
    font-family: sans-serif;
}

html {
    overflow-x: hidden;
    height: 100%;
}

body {
    height: 100%;
    width: 100vw;
}

.box {
    height: 100%;
}

.react-tabs {
    height: 100%;
}

.react-tabs__tab-panel--selected {
    height: 95%;
}

.tab-container {
    height: 100%;
    display: flex;
    flex-flow: column;
    border: black;
    border-width: medium;
}

.filter-panel {
    padding: 20px;
    padding-top: 15px;
    position: absolute;
    width: 500px;
    height: 95%;
    background: gray;
    float: left;
}

.graph {
    padding: 20px;
    padding-top: 15px;
    position: absolute;
    margin-left: 500px;
    height: 100%;
    width: calc(100vw - 500px);
    background: #A1ABB2;
}

.graph-title {
    overflow-wrap: break-word;
    margin-bottom: 5px;
}

.graph-row {
    margin-top: 20px;
    margin-bottom: 10px;
}

.graph-item {
    border-right: 0.2rem solid;
}

.combine {
    position: fixed;
    width: 25%;
}

#slider-div {
    display: flex;
    flex-direction: row;
    margin-top: 30px;
}

#slider-div > div {
    margin: 8px;
}

.slider-label {
    position: absolute;
    background-color: #eee;
    padding: 4px;
    font-size: 0.75rem;
}

.centered-text {
    text-align: center;
    font-size: 20px;
}

.range-slider {
    background: white;
    z-index: 99;
    height: 130px;
    /* width: 100vw; */
    /* position: fixed; */
    /* top: 38px; */
}

#main-list {
    width: 100%;
    margin: 0;
    background: white;
    z-index: 11;
    position: fixed;
    top: 0;
}

#sub-list {
    width: 100%;
    margin: 0;
    background: white;
    z-index: 11;
    position: fixed;
    top: 37px;
}

.filter-button {
    margin-top: 45px;
}

.top-row-short {
    margin-top: 100px;
}

.top-row {
    margin-top: 200px;
}

.carousel-img-big {
    width: max(40vw, 400px);
    height: auto;
    position: absolute;
    left: calc((100vw - 500px) / 4);
    top: 100px;
    border: solid black 3px;
}

.carousel-img-small {
    width: max(400px, 50vw);
    height: auto;
    position: absolute;
    left: calc((100vw - 500px) / 4);
    top: 100px;
    border: solid black 3px;
}

#test-square, #test-square02 {
    padding: 5px;
    color: whitesmoke;
    position: fixed;
    border-radius: 10px;
    text-align: center;
    font-size: 20px;
    left: 15px;
}

.lda-model {
    height: 900px;
    margin:50px 0 0 0;
}

@media (max-width: 1600px) {
    .lda-text {
        width: 100vw;
        padding: 75px;
    }
}

@media (min-width: 1600px) {
    .lda-text {
        width: calc(100vw - 1300px);
        height: 900px;
        overflow-y: auto;
        margin:50px 0 0 0;
    }
    .lda-model {
        width: 1300px;
    }
}

.text-spacing-2 {
    margin-top: calc(0.54 * 100vw);
}

.lda-text-spacing {
    margin: 30px 20px 0 20px;
}

#cluster-display {
    width: 60px;
    height: 25px;
    background: gray;
    color: white;
    z-index: 10;
    position: fixed;
}

/*divider class*/
.cloud-img {
    width: 650px;
    height: auto;
}

.cloud-img img {
    width: min(650px, 100vw);
    height: auto;
}

.cloud-btns {
    width: 100vw;
}

.cluster-button:hover {
    opacity: 60%;
}

@media (max-width: 1000px) {
    .cloud-text {
        width: 100vw;
    }

    .cloud-img {
        width: 100vw;
        height: auto;
    }

    .cloud-img img {
        margin-left: max(calc((100vw - 750px) / 2 - 8px), 0px);
        margin-right: auto;
    }
}

@media (min-width: 1000px) {
    .cloud-text {
        width: calc(100vw - 750px);
        overflow-y: auto;
    }

    .cloud-img {
        width: 750px;
        height: auto;
    }
}

.coauth-network-text {
    margin-left: 50px;
    margin-right: 50px;
}

#view01, #view02, #view03 {
    border: solid black 3px;
}

.legend-button {
    padding: 5px;
    margin: 15px;
    color: whitesmoke;
    width: min(100px, 75%);
}

.selected-legend-button, .selected-legend-button-2, .selected-legend-button-3 {
    border: solid lightgray 2px;
}

.selected-legend-item, .selected-legend-item-2, .selected-legend-item-3 {
    border: solid lightgray 2px;
}

.cluster-legend {
    width: 90vw;
    margin-left: 5vw;
    height: 150px;
}

#citationCar {
    width: 800px;
    height: 800px;
    padding: 0;
}

#network-legend {
    width: calc(100vw - 900px);
    margin-left: 40px;
    height: 800px;
    padding: 0;
    overflow-y: auto;
}

.legend-item {
    width: 100%;
    margin: 20px 0 0 0;
    padding: 0;
}

.legend-btn {
    height: 30px;
    width: 30px;
}

.legend-btn:hover {
    opacity: 80%;
    border: solid white 3px;
}

.legend-text {
    padding: 0;
    margin: 0 0 0 10px;
    width: calc(100% - 40px);
    height: auto;
}

.button-wrapper {
    float: left;
    width: 55px;
}

.button-wrapper input {
    width: 100%;
}

.about-container {
    margin: 50px 0 0 0;
    padding: 0;
    width: calc(100% - 20px);
    height: calc(100vh - 50px);
    overflow-y: hidden;
}

.about-iframe {
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    overflow-y: hidden;
}

.about-iframe-inner {
    overflow-y: hidden;
}

.network-vid-iframe-wrapper {
    margin-left: calc(min(3%, 50px));
    width: 700px;
}

.network-help-text {
    max-width: 900px;
    margin-left: calc(min(3%, 50px));
}